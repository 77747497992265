import config from './config'

enum Env {
  local = "local",
  staging = "staging",
  prod = "prod",
}

function server(env: Env) {
  switch (env) {
    case Env.local: return "http://localhost:8000/api/v1"
    case Env.staging: return "https://api.sct.x2.team/api/v1"
    case Env.prod:
    default: return "https://api.tenzio.ru/api/v1"
  }
}

function getServerEnv() {
  let def: Env = (config.apiServerEnv as Env) || Env.prod
  try {
    if (!document.location) return def
    
    const env = new URLSearchParams(document.location.search).get("env") as Env
    if (env && server(env)) {
      return env
    }
  } catch { }
  
  return def
}


enum RequestMode {
  anonymous, authorized, refresh
}


export class ApiScoot {
    env: Env
    baseUrl: string
    authToken?: string
    refreshToken?: string
    
    constructor() {
      this.env = getServerEnv()
      this.baseUrl = server(this.env)
      this.authToken = undefined
    }
    
    authCheck() {
        return this.post('/auth/check')
    }
    
    authRefresh() {
      return this.post('/auth/refresh', {}, RequestMode.refresh)
    }
    
    authCheckLogin(login: string) {
        return this.post('/auth/checkLogin', { login: login })
    }
    
    authLogin(login: string, pass: string) {
        return this.post('/auth/login', { login: login, password: pass })
    }
    
    authRequestCodeForPhone(phone: string) {
        return this.post('/auth/requestPhoneCode', { phone: phone })
    }
    
    authRequestCodeForEmail(email: string) {
        return this.post('/auth/requestEmailCode', { email: email })
    }
    
    authConfirmPhoneCode(phone: string, code: string ) {
        return this.post('/auth/confirmPhoneCode', { phone: phone, code: code })
    }
    
    authConfirmEmailCode(email: string, code: string ) {
        return this.post('/auth/confirmEmailCode', { email: email, code: code })
    }
    
    authUpdatePassword(pass: string) {
        return this.post('/auth/updatePassword', { password: pass })
    }
    
    authAsAnonymous() {
        return this.post('/auth/anon')
    }
    
    authRefreshAnonymous() {
      return this.post('/auth/anon-refresh')
    }
    
    
    accountGetMy() {
        return this.get('/account/my')
    }
    
    
    deviceGetState(deviceId: string) {
        return this.get(`/device/${deviceId}/info`)
    }
    
    deviceGetControl(deviceId: string) {
        return this.get(`/device/${deviceId}/control`)
    }
    
    deviceOpenLock(deviceId: string) {
        return this.post(`/device/${deviceId}/control/openLock`)
    }
    
    deviceEndRide(deviceId: string, receiptPhone: string | undefined = undefined, receiptEmail: string | undefined = undefined) {
      let body: object = {}
      if (receiptPhone) {
        body = { phone: receiptPhone, ...body }
      }
      if (receiptEmail) {
        body = { email: receiptEmail, ...body }
      }
      
      return this.post(`/device/${deviceId}/control/endRide`, body)
    }
    
    deviceTogglePower(isOn: boolean, deviceId: string) {
      let command: string = isOn ? 'powerOn' : 'powerOff'
      return this.post(`/device/${deviceId}/control/${command}`)
    }
    
    
    orderPrepare(deviceId: string, priceRateId: number) {
        return this.post(`/order/prepare`, { deviceId: deviceId, priceRateId: priceRateId })
    }
    
    orderRecard(deviceId: string, cardId: string, priceRateId: number) {
        return this.post('/order/recard', { deviceId: deviceId, cardId: cardId, priceRateId: priceRateId })
    }
    
    orderCancel(orderId: string) {
        return this.post(`/order/cancel`, { orderId: orderId })
    }
    
    orderGetPricesForDevice(deviceId: string) {
      return this.get(`/device/${deviceId}/prices`)
    }
    
    
    paymentsGet(page: number) {
        return this.get(`/payments/my/${page}`)
    }
    
    paymentsGetForRide(rideId: string) {
      return this.get(`/payments/ride/${rideId}`)
    }
    
    
    cardsGet() {
        return this.get('/cards')
    }
    
    mapGetBig() {
      return this.get('/map/big')
    }
    
    branchGetByDevice(deviceId: string) {
      return this.get(`/branches/device/${deviceId}`)
    }
    
    
    get(endpoint: string, params?: object, mode: RequestMode = RequestMode.authorized) {
      let url = this.baseUrl + endpoint
      if (params) {
        const keys = Object.keys(params)
        url += "?" + keys.map(k => k + "=" + (params as any)[k]).join("&")
      }
      return this.fetchURL(url, mode)
    }
    
    post(endpoint: string, json: object = {}, mode: RequestMode = RequestMode.authorized) {
      return this.jsonRequest("POST", endpoint, mode, json)
    }
    
    patch(endpoint: string, json: object = {}, mode: RequestMode = RequestMode.authorized) {
      return this.jsonRequest("PATCH", endpoint, mode, json)
    }
    
    del(endpoint: string, json: object = {}, mode: RequestMode = RequestMode.authorized) {
      return this.jsonRequest("DELETE", endpoint, mode, json)
    }
    
    jsonRequest = (method: string, endpoint: string, mode: RequestMode, json: object) => {
      return this.fetch(this.baseUrl + endpoint, {
        method,
        headers: {
          "Content-Type": "application/json",
          ...this.headers(mode)
        },
        body: JSON.stringify(json)
      })
    }

    fetchURL = (url: string, mode: RequestMode) => {
      return this.fetch(url, {
        headers: this.headers(mode),
      })
    }

    async fetch(url: string, options: object) {
        const response = await fetch(url, options)
        
        if (!response.ok) {
            let errorMessage = response.statusText
            if (isJSON(response)) {
                const json = await response.json()
                errorMessage = describeError(json)
            }
            throw new ApiError(response.status, errorMessage)
        }
        
        if (!isJSON(response)) {
            throw new Error('Malformed response')
        }
        
        return await response.json()
    }
    
    headers(mode: RequestMode): object {
      switch (mode) {
        case RequestMode.anonymous: return {}
        case RequestMode.authorized: return this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}
        case RequestMode.refresh: return this.refreshToken ? { Authorization: `Bearer ${this.refreshToken}` } : {}
      }
    }
}

function describeError(obj: any): string {
    const keys = Object.keys(obj)
    if (obj.message) {
        return obj.message
    } else if (obj.error) {
        return describeError(obj.error)
    } else if (keys.length) {
        return keys.map(k => {
            const v = obj[k]
            return k + " - " + (Array.isArray(v) ? v[0] : v)
        })[0]
    } else {
        // The worst case
        return "API Error"
    }
}
    
class ApiError extends Error {
    constructor(status: number, message: string) {
        super(message)
        this.status = status
        this.name = this.constructor.name
        
        if (typeof Error["captureStackTrace"] === "function") {
            Error["captureStackTrace"](this, this.constructor)
        } else {
            this.stack = new Error(message).stack
        }
    }
    
    status: number
}

function isJSON(response: Response) {
    const contentType = response.headers.get("content-type")
    return contentType && contentType.includes("application/json")
}

const api = new ApiScoot()

export default api
