export function cloudAddWidgetScript() {
    let script = document.createElement('script')
    script.setAttribute('src', 'https://widget.cloudpayments.ru/bundles/cloudpayments')
    document.body.appendChild(script)
}


export function cloudPay(orderId, userId, amount, currency, description, receiptInfo, onSuccess, onFail, onComplete) {
    var widget = new window.cp.CloudPayments();
    widget.pay('charge', // 'charge' для одностадийного или 'auth' для двухстадийного платежа
        { //options
            publicId: process.env.CLOUDPAYMENTS_ID ?? 'pk_e74346aa5efc277b8d9f05df2cace', //id из личного кабинета
            description: description,
            amount: amount,
            currency: currency,
            invoiceId: orderId,
            accountId: userId,
            skin: "mini",
            data: {
                CloudPayments: receiptInfo,
            },
        },
        {
            onSuccess: onSuccess,
            onFail: onFail,
            onComplete: onComplete
        }
    )
}
