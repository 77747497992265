import React from 'react'
import { isMobile } from 'react-device-detect'
import { YMaps, Map, Placemark, ZoomControl, GeolocationControl } from 'react-yandex-maps'

import { MapCache } from '../lib/entities'
import { MapPoint } from '../lib/types'
import api from '../lib/api'
import config from '../lib/config'

interface IProps { }
interface IState {
  mapCache: MapCache
  isLoaded: boolean
  error: Error | null
}

class BigMapScreen extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      error: null,
      mapCache: {
        id: 'none',
        points: [],
      }
    }
  }
  
  componentDidMount() {
    this.reloadData()
  }
  
  render() {
    return (
      <div className='big-map'>
        <YMaps query={{ apikey: config.ymapsKey }}>
          <Map
            defaultState={{ center: [52.744989, 103.653753], zoom: 14}}
            width='100%' height='100vh'
            modules={['geolocation']}
            instanceRef={ref => {
              if (!ref) return
              
              let map = ref as any
              map.behaviors.disable('scrollZoom')
              
              if (isMobile) {
                map.behaviors.disable('drag')
              }
            }}
          >
            <ZoomControl />
            <GeolocationControl />
            {this.state.mapCache.points.map(point => this.renderPoint(point))}
          </Map>
        </YMaps>
      </div>
    )
  }
  
  renderPoint(point: MapPoint) {
    return <Placemark geometry={[point.x, point.y]} />
  }
  
  reloadData() {
    api.mapGetBig().then(
      (result) => {
        this.setState({
          isLoaded: true,
          mapCache: result,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default BigMapScreen
