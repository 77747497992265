import { cloudAddWidgetScript as goAddWidgetScript, cloudPay as goPay } from './payment/cloudpayments-js'
// import { b2pAddWidgetScript as goAddWidgetScript, b2pPay as goPay } from './payment/best2pay-js'
// import { tkAddWidgetScript as goAddWidgetScript, tkPay as goPay } from './payment/tinkoff-js'

interface PaymentInfo {
    orderId: string
    accountId: string
    amount: number
    currency: string
    description: string
    receiptInfo?: object
    onSuccess: (options: object) => void
    onFail: (reason: string, options: object) => void
    onComplete: (options: object) => void
}

export function addWidgetScript() {
    goAddWidgetScript()
}

export function pay(info: PaymentInfo) {
    goPay(info.orderId, info.accountId, info.amount, info.currency, info.description, info.receiptInfo, info.onSuccess, info.onFail, info.onComplete)
}
